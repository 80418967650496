<template>
    <ORowContainer ref="checklistsContainer" @input="setActive" @click="setActive" @focusout="handleFocusOut">
        <div v-if="Object.keys(data).length > 0">
            <Checklist :data="data" />
        </div>
    </ORowContainer>
</template>

<script setup lang="ts">
    import Checklist from 'o365.libraries.vue.components.Checklists.Checklist2.vue'
    import { nextTick, ref, onUnmounted } from 'vue';

    const props = defineProps({
        data: {
            type: Object,
            required: true
        }
    });

    const emit = defineEmits(['focusout']);
    const checklistsContainer = ref(null);
    const isActive = ref(false);

    function handleFocusOut(e) {
        if (!isActive.value) {
            return;
        }

        const checklistsWrapper = checklistsContainer.value?.$el;
        if (!e.relatedTarget || !checklistsWrapper.contains(e.relatedTarget)) {
            setInactive();
            emit('focusout');
        }
    }

    function setActive() {
        if (!isActive.value) {
            isActive.value = true;
        }
    }

    function setInactive() {
        isActive.value = false;
    }

    async function createChecklist() {
        const checklists = {
            'name': '',
            'checklistItems': ''
        }
        let index

        if (Object.keys(props.data).length > 0) {
            index = props.data.checklists.push(checklists) - 1
        } else {
            props.data['checklists'] = [checklists]
            index = 0
        }

        await nextTick()

        document.getElementById(`checklist_${index}`)?.focus()
    }

    onUnmounted(() => {
        document.removeEventListener("click", setInactive);
    });

    defineExpose({ createChecklist });
</script>